import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { CompanyType } from "../types/companyTypes";
import { ProjectType } from "../types/projectTypes";
import { db } from "./firebase";

export const getCompanies = async () => {
    const q = query(collection(db, 'companies'), orderBy('name'));
    const snapshot = await getDocs(q);
    const companies = snapshot.docs.map(
        (doc: any) =>
        ({
            id: doc.id,
            ...doc.data(),
        } as CompanyType)
    );
    return companies;
}

export const getProjects = async () => {
    try {
        const q = query(collection(db, 'projects'), orderBy('title'));
        const snapshot = await getDocs(q);
        const projects = snapshot.docs.map(
            (doc: any) =>
            ({
                id: doc.id,
                ...doc.data(),
            } as ProjectType)
        );
        return projects;
    } catch (ex) {
        console.error(ex)
        return []
    }
}


export const getUsers = async (roles: string[]) => {
    if (!roles.includes('admin')) return;
    const q = query(collection(db, 'users'));
    const snapshot = await getDocs(q);
    const users = snapshot.docs.map((doc: any) => ({
        uid: doc.id,
        ...doc.data(),
    }));
    return users;
}
