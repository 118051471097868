import { Button } from '@limeyfy/react-client-cms';
import { User } from 'firebase/auth';
import { createContext } from 'react';
import Container from '../components/Container';
import Footer from '../components/Footer';
import useUser, { AccountType, RoleType } from '../hooks/useUser';
import { auth } from '../lib/firebase';
import Login from '../pages/Login';
import MissingAccountInfo from '../pages/MissingAccountInfo';

export interface IAuthContext {
	user: User | null;
	loading: boolean;
	token: string;
	role: RoleType | null;
	hasPermission: boolean;
	account: AccountType | null;
}

export const AuthContext = createContext<IAuthContext>({
	user: null,
	loading: false,
	token: '',
	role: null,
	hasPermission: false,
	account: null,
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const user = useUser();

	if (user.loading || user.accountLoading) {
		return (
			<div
				suppressHydrationWarning
				className='flex justify-center items-center min-h-screen'
			>
				<div className='spinner animate-spin text-4xl duration-1000 border-2 rounded-full border-r-0 border-t-0 border-limeyfy-600 w-16 h-16' />
			</div>
		);
	}

	if (!user.user) {
		return <Login />;
	}

	if (!user.role) {
		return (
			<AuthContext.Provider value={user}>
				<Container
					className='flex h-full items-center mb-0 min-h-screen'
					innerClassName='max-w-xl'
				>
					<div className='text-center bg-white shadow-md px-4 py-6 rounded-lg'>
						<h2 className='text-2xl font-semibold'>Ingen tilgang</h2>
						<p>
							Hvis dette er en feil vensligst send en mail til mkm@limeyfy.no
						</p>

						<Button className='mt-5' onClick={() => auth.signOut()}>
							Bytt konto
						</Button>
					</div>
				</Container>
			</AuthContext.Provider>
		);
	}
	if (!user.account?.firstName || !user.account.lastName) {
		return (
			<AuthContext.Provider value={user}>
				<MissingAccountInfo />
			</AuthContext.Provider>
		);
	}

	return (
		<AuthContext.Provider value={user}>
			{children}
			<Footer />
		</AuthContext.Provider>
	);
};

export default AuthProvider;
