const Footer = () => (
	<footer className='flex justify-center items-center flex-col py-6'>
		<p className='text-sm text-gray-500'>
			&copy; {new Date().getFullYear()} -{' '}
			<a href='https://limeyfy.no' className='text-green-500'>
				Limeyfy
			</a>
		</p>
		<a className='mt-3 text-green-500 text-sm' href='https://limeyfy.no/admin'>
			Admin dashboard
		</a>
	</footer>
);

export default Footer;
