import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import AuthProvider from './providers/AuthProvider';
import { BetterHelmet } from '@limeyfy/react-seo';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<div className='min-h-screen w-full bg-gray-100'>
			<Toaster position='top-center' reverseOrder={false} />
			<BetterHelmet title='Timereg | Limeyfy' robots='noindex, nofollow' />
			<AuthProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</AuthProvider>
		</div>
	</React.StrictMode>
);
