import toast from "react-hot-toast";

export default class CErr {
    static catchError(func: () => void) {
        return () => {
            try {
                func();
            } catch (error) {
                console.log(error);
            }
        }
    }
    static catchAsyncError(func: () => Promise<void>) {
        return async () => {
            try {
                await toast.promise(func(), {
                    loading: 'Loading...',
                    success: 'Success!',
                    error: 'Error!',
                })
            } catch (error) {
                console.log(error);
            }
        }
    }
}