import { User } from "firebase/auth";
import { addDoc, collection, DocumentData, getDocs, limit, orderBy, Query, query, Timestamp, where } from "firebase/firestore";
import toast from "react-hot-toast";
import { HourDto, HourType } from "../types/hourTypes";
import { db } from "./firebase";

export const getHours = async (uid: string, {
    limit: LIMIT = 10,
    query: preQ,
}: {
    limit?: number;
    query?: Query<DocumentData>;
}) => {
    const q = query(
        collection(db, 'hours'),
        orderBy('date', 'desc'),
        where('userId', '==', uid),
        limit(LIMIT)
    );
    const snapshot = await getDocs(preQ || q);
    const docs = snapshot.docs;
    const hours = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...(doc.data() as HourType),
    }));
    return {
        hours,
        last: docs[docs.length - 1],
        len: docs.length
    };
};

export const addHour = async (data: HourDto, project: string | null, user: User) => {
    // map dto to HourType
    const hour: HourType = {
        ...data,
        userId: user.uid,
        date: Timestamp.fromDate(new Date(data.date)),
        company: data.company.id,
        project: project,
    };
    await handlePromise(async () => {
        await addDoc(collection(db, 'hours'), hour);
    }, "La til en ny time!");
};

export const handlePromise = async (promise: () => Promise<unknown>, options: string | {
    loading?: string;
    error?: string;
    success?: string;
}) => {
    const opts = typeof options === 'string' ? { success: options } : options;

    const pr = new Promise((res, rej) => promise().then(res).catch(rej));

    return await toast.promise(pr, {
        loading: opts.loading || 'Laster...',
        error: opts.error || 'Det skjedde noe feil',
        success: opts.success || 'La til ny time!',
    });
}
