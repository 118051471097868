import { Button, LabelContainer, TextInput } from '@limeyfy/react-client-cms';
import { doc, updateDoc } from 'firebase/firestore';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import Container from '../components/Container';
import { db } from '../lib/firebase';
import { AuthContext } from '../providers/AuthProvider';

const MissingAccountInfo = () => {
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const { user } = useContext(AuthContext);

	const handleAddMissingData = async () => {
		// check if firstName and lastName are empty or less than 2 characters
		// if so, return
		// otherwise, add the data to the user's account
		if (firstName.length < 2 || lastName.length < 2) return;
		// add data to user's account
		await toast.promise(
			updateDoc(doc(db, 'users', user?.uid ?? ''), {
				firstName,
				lastName,
			}),
			{
				loading: 'Laster...',
				error: 'Det skjedde noe feil',
				success: 'Oppdaterte informasjonen på deg😆',
			}
		);
	};

	return (
		<Container
			innerClassName='max-w-xl'
			className=' min-h-screen flex items-center mb-0'>
			<div className='text-center bg-white shadow-md px-4 py-6 rounded-lg'>
				<h2 className='text-2xl font-medium'>
					Vi mangler vist litt informasjon på deg😅
				</h2>
				<div className='text-left py-4 flex flex-col gap-y-4'>
					<LabelContainer label='Fornavn'>
						<TextInput
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</LabelContainer>
					<LabelContainer label='Etternavn'>
						<TextInput
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</LabelContainer>
					<Button onClick={handleAddMissingData}>Lagre</Button>
				</div>
			</div>
		</Container>
	);
};

export default MissingAccountInfo;
