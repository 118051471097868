import React from 'react';
import clsx from 'clsx';

const Container: React.FC<{
	children: React.ReactNode;
	className?: string;
	innerClassName?: string;
}> = ({ children, className, innerClassName }) => {
	return (
		<div className={clsx('flex justify-center pt-10 pb-10 mx-5', className)}>
			<div className={clsx('max-w-5xl w-full', innerClassName)}>{children}</div>
		</div>
	);
};

export default Container;
