import { Button } from '@limeyfy/react-client-cms';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import CErr from '../classes/CErr';
import Container from '../components/Container';
import { auth, db } from '../lib/firebase';

const Login = () => {
	const handleLogin = async () => {
		try {
			const res = await signInWithPopup(auth, new GoogleAuthProvider());
			await updateDoc(doc(db, 'users', res.user.uid), {
				name: res.user.displayName,
				email: res.user.email,
				photoUrl: res.user.photoURL,
			});
		} catch (ex) {
			console.error('Login error', ex);
		}
	};

	return (
		<Container
			className='flex h-full items-center mb-0 min-h-screen'
			innerClassName='max-w-sm'
		>
			<Button onClick={CErr.catchAsyncError(handleLogin)} className='w-full'>
				Sign in with google
			</Button>
		</Container>
	);
};

export default Login;
