import { Transition } from '@headlessui/react';
import { ClientCms } from '@limeyfy/react-client-cms';
import { Fragment, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { addHour } from '../lib/hours';
import { AuthContext } from '../providers/AuthProvider';
import { CompanyType } from '../types/companyTypes';
import { HourDto } from '../types/hourTypes';
import { ProjectType } from '../types/projectTypes';

const FillInWorkComponent = ({
	companies,
	close,
	open,
	projects,
}: {
	companies: CompanyType[];
	close: () => void;
	open: boolean;
	projects: ProjectType[];
}) => {
	const { user } = useContext(AuthContext);
	const [selectedCompany, setSelectedCompany] = useState(
		companies.find((x) => x.name === 'Limeyfy Myhre')?.id || companies[0]?.id
	);
	const availableProjects = projects.filter(
		(x) => x.company === selectedCompany
	);

	const handleAddHour = async (data: HourDto) => {
		if (!user) return;
		const project = projects?.find(
			(x) => x.id === data.project?.id && x.company === data.company.id
		);
		if (!project && data.project?.id)
			return toast.error('Fant ikke prosjektet');

		await addHour(data, project?.id || null, user);
		close();
	};

	return (
		<Transition
			as={Fragment}
			show={open && companies.length > 0}
			enter='transform transition duration-[400ms]'
			enterFrom='opacity-0 rotate-[-120deg] scale-50'
			enterTo='opacity-100 rotate-0 scale-100'
			leave='transform duration-200 transition ease-in-out'
			leaveFrom='opacity-100 rotate-0 scale-100 '
			leaveTo='opacity-0 scale-95 '
		>
			<div className='bg-white shadow-md px-4 py-2 rounded-lg my-4'>
				<ClientCms<HourDto>
					onSubmit={handleAddHour}
					className='mt-8 max-w-5xl'
					fields={[
						{
							name: 'title',
							label: 'Tittel',
							defaultValue: 'Utvikling',
						},
						{
							name: 'description',
							label: 'Beskrivelse (Forklaring på arbeid)',
							type: 'text',
						},
						{
							name: 'hours',
							label: 'Timer',
							type: 'number',
							defaultValue: 1,
						},
						{
							name: 'company',
							label: 'Bedrift',
							type: 'select',
							options: companies,
							renderLabel: (company?: CompanyType) => company?.name,
							defaultValue: companies.find((x) => x.id === selectedCompany),
							onChange: (company?: CompanyType) => {
								company && setSelectedCompany(company.id);
								return company;
							},
						},
						{
							name: 'project',
							label: 'Prosjekt',
							type: 'select',
							options: availableProjects,
							renderLabel: (project: ProjectType) => project.title,
							defaultValue: availableProjects[0],
						},
						{
							label: 'Dato',
							name: 'date',
							type: 'date',
						},
					]}
				/>
			</div>
		</Transition>
	);
};

export default FillInWorkComponent;
