import { onAuthStateChanged, User } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { auth, db } from '../lib/firebase';

export type RoleType = {
    id: string;
    roles: string[];
};

export type AccountType = {
    uid: string;
    displayName: string;
    email: string;
    photoURL: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
}

const useUser = () => {
    const [user, setUser] = useState<null | User>(null);
    const [loading, setLoading] = useState(true);
    const [accountLoading, setAccountLoading] = useState(true);
    const [token, setToken] = useState('');
    const [role, setRole] = useState<RoleType | null>(null);
    const [account, setAccount] = useState<AccountType | null>(null)

    // Listen to user state changes
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setUser(user);
            setLoading(false);
            const token = await user?.getIdToken();
            if (token) {
                setToken(token);
            }
        });
        return () => unsubscribe();
    }, []);

    // listen to role and account changes
    useEffect(() => {
        if (!user) return;

        // listen to role changes
        const ref = doc(db, 'roles', user.uid);
        const unsubscribe = onSnapshot(ref, (doc) => {
            if (!doc.exists) return;
            const data = doc.data();
            if (data) {
                setRole({ ...(data as RoleType), id: doc.id });
            }
        });

        // listen to account changes
        const accountRef = doc(db, 'users', user.uid);
        const unsubscribe2 = onSnapshot(accountRef, (doc) => {
            setAccountLoading(false);
            if (!doc.exists) return;
            let data: AccountType = { ...doc.data() as AccountType, uid: doc.id };
            data.fullName = `${data.firstName} ${data.lastName}`;
            setAccount({ ...(data as AccountType), uid: doc.id });
        });


        return () => {
            unsubscribe();
            unsubscribe2();
        };
    }, [user])

    return {
        user: user || null,
        loading,
        token,
        role,
        hasPermission: (role?.roles.includes('admin') || role?.roles.includes('employee')) as boolean,
        account,
        accountLoading
    };
};

export default useUser;
