import { Button, SelectComponent, TextInput } from '@limeyfy/react-client-cms';
import { collection, getDocs, query } from 'firebase/firestore';
import { useContext, useEffect, useReducer, useState } from 'react';
import useSWR from 'swr';
import Container from '../components/Container';
import FillInWorkComponent from '../components/FillInWorkComponent';
import ViewHours from '../components/ViewHours';
import { getCompanies, getProjects } from '../lib/dataLoading';
import { auth, db } from '../lib/firebase';
import { AuthContext } from '../providers/AuthProvider';
import { CompanyType } from '../types/companyTypes';
import { ProjectType } from '../types/projectTypes';

const Home = () => {
	const [addHour, toggleAddHour] = useReducer((s) => !s, false);
	const { data: companies } = useSWR('companies', getCompanies);
	const { user, role } = useContext(AuthContext);
	const [users, setUsers] = useState<
		{ email: string; uid: string; displayName: string }[] | null
	>(null);
	const [uid, setUid] = useState(user?.uid || '');
	const [loading, setLoading] = useState(true);
	const [company, setCompany] = useState<CompanyType | null>(null);
	const [since, setSince] = useState<string | null>(null);
	const { data: projects } = useSWR<ProjectType[]>('projects', getProjects);

	useEffect(() => {
		const fetchUsers = async () => {
			if (!role?.roles.includes('admin')) return;
			const q = query(collection(db, 'users'));
			const snapshot = await getDocs(q);
			const users = snapshot.docs.map((doc: any) => ({
				uid: doc.id,
				...doc.data(),
			}));
			setUsers(users);
		};
		const fetchData = async () => await Promise.all([fetchUsers()]);
		fetchData().finally(() => setLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading)
		return (
			<div
				suppressHydrationWarning
				className='flex justify-center items-center min-h-screen'
			>
				<div className='spinner animate-spin text-4xl duration-1000 border-2 rounded-full border-r-0 border-t-0 border-limeyfy-600 w-16 h-16' />
			</div>
		);

	return (
		<Container>
			<div className='py-3 bg-white shadow-md rounded-lg mb-12'>
				<div className='flex items-center justify-between px-4'>
					<p>
						{user?.displayName} ({role?.roles.join(', ')})
					</p>
					<div className='flex items-center'>
						<img
							src={user?.photoURL ?? ''}
							alt=''
							className='w-12 h-12 bg-gray-300 mr-6 rounded-full'
						/>
						<Button
							size='sm'
							className='bg-red-100 !text-red-600 hover:bg-red-200'
							variant='secondary'
							onClick={() => auth.signOut()}
						>
							Logout
						</Button>
					</div>
				</div>
			</div>
			<div className='flex justify-between'>
				<h1 className='text-3xl font-medium'>Mine timer</h1>
				<Button onClick={toggleAddHour} className='flex items-center gap-x-1'>
					{!addHour ? 'Registrer arbeid' : 'Lukk'}
				</Button>
			</div>
			{projects && (
				<FillInWorkComponent
					close={toggleAddHour}
					companies={companies || []}
					open={addHour}
					projects={projects}
				/>
			)}
			{users ? (
				<div className='max-w-lg my-4'>
					<SelectComponent
						name={'email'}
						options={users}
						renderLabel={(user: any) => (
							<>
								<span className='font-mono'>
									{user.firstName
										? `${user.firstName} ${user.lastName}`
										: user.displayName}
								</span>
								<span className='text-xs ml-4'>[{user.email}]</span>
							</>
						)}
						type={'select'}
						value={users.find((x) => x.uid === uid)}
						onChange={(user: any) => setUid(user.uid)}
					/>
				</div>
			) : (
				<div className='py-2' />
			)}
			<div className='flex gap-x-3 items-center'>
				<div className='w-full'>
					<SelectComponent
						name={'name'}
						options={[...(companies || []), { id: 'all', name: 'Alle' }]}
						renderLabel={(company: CompanyType) => (
							<span className='font-mono'>{company.name}</span>
						)}
						type={'select'}
						onChange={(c: CompanyType) =>
							c.id !== 'all' ? setCompany(c) : setCompany(null)
						}
						label={'Bedrift'}
						nullValueText={'Alle'}
						value={company || { id: 'all', name: 'Alle' }}
					/>
				</div>
				<div className='flex items-center gap-x-2'>
					<TextInput
						type='date'
						value={since ?? ''}
						onChange={(e) => setSince(e.target.value)}
					/>
					<Button onClick={() => setSince(null)}>Reset</Button>
				</div>
			</div>
			<ViewHours
				isMe={uid === user!.uid}
				uid={uid}
				companies={companies || []}
				filter={(p) =>
					p.filter((x) => {
						if (!company && !since) return true;
						if (company && !since) {
							return x.company === company.id;
						}
						if (!company && since) {
							return x.date.toMillis() >= new Date(since).getTime();
						}
						return (
							x.company === company!.id &&
							x.date.toMillis() >= new Date(since!).getTime()
						);
					})
				}
			/>
		</Container>
	);
};

export default Home;
