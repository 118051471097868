import { Dialog, Transition } from '@headlessui/react';
import { Button } from '@limeyfy/react-client-cms';
import { Fragment } from 'react';
import { HourType } from '../types/hourTypes';

const ShowHourModal = ({
	open,
	close,
	hour,
	onDelete,
}: {
	open: boolean;
	close: () => void;
	hour: HourType;
	onDelete: () => void;
}) => (
	<Transition.Root show={open} as={Fragment}>
		<Dialog as='div' className='relative z-10' onClose={close}>
			<Transition.Child
				as={Fragment}
				enter='ease-out duration-300'
				enterFrom='opacity-0'
				enterTo='opacity-100'
				leave='ease-in duration-200'
				leaveFrom='opacity-100'
				leaveTo='opacity-0'
			>
				<div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
			</Transition.Child>

			<div className='fixed inset-0 z-10 overflow-y-auto'>
				<div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
					<Transition.Child
						as={Fragment}
						enter='ease-out duration-300'
						enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
						enterTo='opacity-100 translate-y-0 sm:scale-100'
						leave='ease-in duration-200'
						leaveFrom='opacity-100 translate-y-0 sm:scale-100'
						leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
					>
						<Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg'>
							<div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
								<h3 className='text-lg font-medium mb-2'>{hour.title}</h3>
								<p className='text-gray-800 mb-4 text-sm'>{hour.description}</p>
								<p className='text-gray-600 text-xs'>
									{new Date(hour.date.toDate()).toLocaleDateString('nb-NO')}
								</p>
								<div className='flex justify-end mt-4'>
									<Button
										onClick={onDelete}
										className='bg-red-500 hover:bg-red-600'
									>
										Delete
									</Button>
								</div>
							</div>
						</Dialog.Panel>
					</Transition.Child>
				</div>
			</div>
		</Dialog>
	</Transition.Root>
);

export default ShowHourModal;
