import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import '@limeyfy/react-client-cms/dist/tailwind.css';
import { SWRConfig } from 'swr';

function App() {
	return (
		<SWRConfig
			value={{
				refreshInterval: 0,
				revalidateOnFocus: false,
				revalidateOnReconnect: false,
			}}
		>
			<Routes>
				<Route path='*' element={<Home />} />
				<Route
					path='*'
					element={
						<div>
							<p>Not found</p>
						</div>
					}
				/>
			</Routes>
		</SWRConfig>
	);
}

export default App;
