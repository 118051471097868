import { Button } from '@limeyfy/react-client-cms';
import {
	collection,
	deleteDoc,
	doc,
	DocumentData,
	limit,
	orderBy,
	query,
	QueryDocumentSnapshot,
	startAfter,
	where,
} from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../lib/firebase';
import { getHours } from '../lib/hours';
import { CompanyType } from '../types/companyTypes';
import { HourType } from '../types/hourTypes';
import ShowHourModal from './ShowHourModal';

const Cache = new Map();

const ViewHours: React.FC<{
	isMe?: boolean;
	uid: string;
	companies: CompanyType[];
	filter: (projects: HourType[]) => HourType[];
}> = ({ isMe = true, uid, companies, filter }) => {
	const [last, setLast] = useState<QueryDocumentSnapshot<DocumentData>>();
	const [hours, setHours] = useState<HourType[]>([]);
	const [reachedEnd, setReachedEnd] = useState(false);
	const [showHour, setShowHour] = useState(false);
	const [hour, setHour] = useState<HourType>();

	useEffect(() => {
		const fetchHours = async () => {
			const { hours, last, len } = await getHours(uid, {});
			setHours(hours);
			setHour(hours[0]);
			setLast(last);
			if (len < 10) {
				setReachedEnd(true);
				Cache.set(`${uid}-reachedEnd`, true);
			}
			Cache.set(uid, hours);
			Cache.set(`${uid}-last`, last);
		};
		fetchHours();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid]);

	const handleLoadMore = async () => {
		if (!last) return;
		const {
			hours,
			last: l,
			len,
		} = await getHours(uid, {
			query: query(
				collection(db, 'hours'),
				orderBy('date', 'desc'),
				where('userId', '==', uid),
				startAfter(last),
				limit(10)
			),
		});
		setHours((s) => [...s, ...hours]);
		setLast(l);
		if (len < 10) setReachedEnd(true);
	};

	const handleDelete = async () => {
		if (!hour || !hour.id) return;
		const confirmed = await window.confirm(
			'Are you sure you want to delete this hour?'
		);
		setShowHour(false);
		if (!confirmed) return;
		await deleteDoc(doc(db, 'hours', hour.id));
		setHours((s) => s.filter((h) => h.id !== hour.id));
	};

	return (
		<>
			{hour && (
				<ShowHourModal
					open={showHour}
					close={() => setShowHour(false)}
					hour={hour}
					onDelete={handleDelete}
				/>
			)}
			{hours.length > 0 ? (
				<>
					<div className='mt-8 flex flex-col mb-5'>
						<div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
							<div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
								<div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg'>
									<table className='min-w-full divide-y divide-gray-300'>
										<thead className='bg-gray-50'>
											<tr>
												<th
													scope='col'
													className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
												>
													Name
												</th>
												<th
													scope='col'
													className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
												>
													Company
												</th>
												<th
													scope='col'
													className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
												>
													Description
												</th>
												<th
													scope='col'
													className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
												>
													Hours
												</th>
												<th
													scope='col'
													className='relative py-3.5 pl-3 pr-4 sm:pr-6'
												>
													<span className='sr-only'>Edit</span>
												</th>
											</tr>
										</thead>
										<tbody className='bg-white'>
											{hours.map((hour, idx) => (
												<tr
													key={hour.id}
													className={idx % 2 === 0 ? undefined : 'bg-gray-50'}
												>
													<td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6'>
														{hour.title}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
														{companies.find((x) => x.id === hour.company)?.name}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500 truncate max-w-[150px]'>
														{hour.description}
													</td>
													<td className='whitespace-nowrap px-3 py-4 text-sm text-gray-500'>
														{hour.hours}
													</td>
													<td className='relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6'>
														<button
															onClick={() => {
																setHour(hour);
																setShowHour(true);
															}}
															className='text-green-500 hover:text-green-800'
														>
															View<span className='sr-only'>, {hour.id}</span>
														</button>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					{!reachedEnd && (
						<div className='flex justify-center'>
							<Button onClick={handleLoadMore}>Last inn flere timer</Button>
						</div>
					)}
					<div className='mt-3'>
						Amount of hours loaded:{' '}
						{filter(hours).reduce((a, b) => a + b.hours, 0)}
					</div>
				</>
			) : (
				<p className='mt-12 text-gray-500 text-center'>
					{isMe ? 'Du har ingen registrerte timer' : 'Ingen registrerte timer'}
				</p>
			)}
		</>
	);
};

export default ViewHours;
